import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Paper, Button,ButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Line, Bar, Radar, Gauge } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { auth, db } from './firebase';  // Importar autenticación y Firestore
import { signInWithEmailAndPassword, signInAnonymously, signOut, onAuthStateChanged } from 'firebase/auth';
import { collection,query,orderBy,limit,getDocs, onSnapshot } from 'firebase/firestore';  // Importar Firestore
import predictions from './data/predictions.json';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GoogleMap, LoadScript } from '@react-google-maps/api'; // Importa Google Map
import GaugeChart from 'react-gauge-chart';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend,ChartDataLabels, Filler);

function App() {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [caudalData, setCaudalData] = useState({});
  const [loading, setLoading] = useState(true);  // Estado de carga
  const [errorFirestore, setErrorFirestore] = useState('');  // Estado para manejar errores de Firestore
  const [lecturas, setLecturas] = useState([]);  // Declaración de lecturas y su estado
  const [geoJsonUrl, setGeoJsonUrl] = useState(null); // Para almacenar la URL del archivo GeoJSON

  // Estado para el centro del mapa, predeterminadas Buena Vista
  const [mapCenter, setMapCenter] = useState({ lat: 9.450, lng: -83.658 });

  // Estado para el valor seleccionado del Button Group (Precipitación, Caudal o Alerta)
  const [selectedVariable, setSelectedVariable] = useState('Alerta');

  //Constante para multirio
  const [selectedRio, setSelectedRio] = useState('rio_p_bv');  // Para seleccionar el río default
  const [alertasGenerales, setAlertasGenerales] = useState([]);  // Para mostrar alertas generales de cada río
  const [chartData, setChartData] = useState(null);

  //Agregar los umbrales de caudal por río
  const umbralesCaudal = {
    'rio_p_bv': { azul: 15, verde: 50, amarillo: 100, naranja: 200, rojo: 225 },
    'rio_ipis': { azul: 1.5, verde: 5, amarillo: 10, naranja: 20, rojo: 25 },
    'rio_ma_1': { azul: 5, verde: 20, amarillo: 50, naranja: 75, rojo: 100 },
    //'rio_tiribi_1': { azul: 15, verde: 50, amarillo: 100, naranja: 100, rojo: 150 },
    // Agrega más ríos según sea necesario
  };

  // Agregar coordenadas de cada río
  const coordenadasRios = {
    'rio_p_bv': { lat: 9.450, lng: -83.658 },  // Coordenadas de Río Buena Vista (ejemplo)
    'rio_ipis': { lat: 9.968, lng: -84.039 },  // Coordenadas de Río Ipis (ejemplo)
    'rio_ma_1': { lat: 9.916, lng: -84.042 },  // Coordenadas de Río Ipis (ejemplo)
    // Agrega más ríos según sea necesario
  };

  // Estaciones y pesos thiessen por río
  const estacionesRios = {
    'rio_p_bv': { estaciones: ['imn_buena_vista', 'imn_chirripo', 'imn_montecarlo'], pesos: [0.5553, 0.0174, 0.4273] },
    'rio_ipis': { estaciones: ['imn_84139', 'imn_84213'], pesos: [0.29, 0.71] },
    'rio_ma_1': { estaciones: ['imn_84139', 'imn_84249', 'imn_84213'], pesos: [0.666, 0.208,0.126] },
    // Agrega más ríos con estaciones y pesos según sea necesario
  };

  // Agregar porcentajes de arco para cada río para la alerta
  const arcoPorcentajes = {
    'rio_p_bv': [0.067, 0.156, 0.222, 0.444, 0.111],  // Ejemplo para el Río Buena Vista
    'rio_ipis': [0.1, 0.2, 0.3, 0.2, 0.2],  // Ejemplo para el Río Ipis
    'rio_ma_1': [0.05, 0.1, 0.35, 0.25, 0.25],  // Ejemplo para el Río Ipis
    // Agrega más configuraciones según sea necesario
  };

  
  //tamaño y centro del mapa de google maps
  const mapContainerStyle = {
    width: '100%',
    height: '250px',
  };
  
  const center = {
    lat: 9.450,  // Latitud y longitud de ejemplo para Costa Rica
    lng: -83.658,
  };

  const options = {
    //zoom: 13,
    center: center,
    //mapId: '4dad983352d7a460', // El mapId proporcionado
    //mapTypeId: 'roadmap', // Tipo de mapa, puedes cambiarlo a 'satellite' si es necesario
  };

  // Función para manejar la selección del río
  const handleRioSelection = (event) => {
    const nuevoRio = event.target.value;
    setSelectedRio(nuevoRio);
    // Actualizamos el centro del mapa según el río seleccionado
    if (coordenadasRios[nuevoRio]) {
      setMapCenter(coordenadasRios[nuevoRio]);
    }
  };

    // Función para convertir timestamp en formato legible
  // Función para convertir timestamp en formato legible
  const formatTimestamp = (timestamp, hoursToAdd = 0) => {
    if (!timestamp) return 'Sin datos';

    // Si el timestamp es un objeto Firestore Timestamp
    if (timestamp.toDate) {
      let date = timestamp.toDate();  // Convertir Firestore Timestamp a Date
      date.setHours(date.getHours() + hoursToAdd);  // Sumar horas si es necesario
      date.setMinutes(0);  // Cambiar los minutos a 0
      return date.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Para formato 24 horas sin AM/PM
      });
    }

    // Si el timestamp es una cadena (formato YYYYMMDDHHMM)
    if (typeof timestamp === 'string') {
      const year = timestamp.substring(0, 4);
      const month = timestamp.substring(4, 6) - 1;  // Los meses en JavaScript comienzan desde 0
      const day = timestamp.substring(6, 8);
      const hour = timestamp.substring(8, 10);
      

      let date = new Date(year, month, day, hour, 0);  // Cambiar los minutos a 0
      date.setHours(date.getHours() + hoursToAdd);  // Sumar horas si es necesario
      return date.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Para formato 24 horas sin AM/PM
      });
    }

    return 'Formato no válido';
  };

  // Función para convertir timestamps a la zona horaria UTC-6 y devolver una fecha legible
  // Función para convertir el campo Fecha a un formato legible
  const formatFecha = (fecha) => {
    // Si la fecha es un objeto de Firestore Timestamp
    if (fecha && fecha.seconds) {
      const date = new Date(fecha.seconds * 1000);  // Convertir segundos a milisegundos
      return date.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Para formato 24 horas sin AM/PM
      });
    }
    
    // Si la fecha está en formato de cadena
    if (typeof fecha === 'string') {
      return fecha;  // Ya está formateada
    }
    
    return 'Sin datos';  // Fallback si no hay fecha disponible
  };

  // Extraer x-axis labels para los gráficos de caudal a partir de las fechas de los modelos 0h, 1h, y 2h
  const xAxisLabels = [
    formatTimestamp(caudalData.mapa_0h?.timestamp),  // Sin cambios para 0h
    formatTimestamp(caudalData.mapa_1h?.timestamp, 1),  // Sumar 1 hora para 1h
    formatTimestamp(caudalData.mapa_2h?.timestamp, 2),  // Sumar 2 horas para 2h
  ];

  // Configuración de GaugeChart para diferentes ríos
  const renderGaugeChart = () => {
    const caudalValues = [
      caudalData.mapa_0h?.pred_caudal || 0,
      caudalData.mapa_1h?.pred_caudal || 0,
      caudalData.mapa_2h?.pred_caudal || 0,
    ];
    
    const umbrales = getUmbralesCaudal(selectedRio);  // Obtener umbrales dinámicos según el río
    const gaugePercent = parseFloat(Math.min(caudalValues[0] / umbrales.rojo, 1).toFixed(3)); 

    return (
      <GaugeChart
        id="gauge-chart"
        nrOfLevels={100}
        arcsLength={arcoPorcentajes[selectedRio] || [0.2, 0.2, 0.2, 0.2, 0.2]}  // Utilizamos los valores por río
        percent={gaugePercent}
        colors={colores_umbral}
        arcWidth={0.6}
        needleColor="#000000"
        needleBaseColor="#333"
        textColor="#000"
        animate={true}
        style={{ width: 200, height: 100 }}
        hideText={true}
      />
    );
  };

  //Función para obtener los umbrales según el río y valores por defecto
  const getUmbralesCaudal = (rio) => {
    return umbralesCaudal[rio] || { azul: 1, verde: 15, amarillo: 50, naranja: 100, rojo: 200 };  // Valores por defecto
  };

  // Función para calcular el color y alerta basado en el caudal y umbrales
  const calcularColorYAlerta = (caudal, umbrales, label) => {
    let color, alerta;

    if (caudal < umbrales.azul) {
      color = 'rgba(0,123,255,0.7)';  // Azul
      alerta = `${label} - Azul: Río en condición calma`;
    } else if (caudal < umbrales.verde) {
      color = 'rgba(40,167,69,0.7)';  // Verde
      alerta = `${label} - Verde: Río ligeramente crecido en condición controlada`;
    } else if (caudal < umbrales.amarillo) {
      color = 'rgba(255,193,7,0.7)';  // Amarillo
      alerta = `${label} - Amarilla: Río crecido en una condición controlada`;
    } else if (caudal < umbrales.naranja) {
      color = 'rgba(255,87,34,0.7)';  // Naranja
      alerta = `${label} - Naranja: Río muy crecido con potenciales desbordamientos`;
    } else {
      color = 'rgba(255,0,0,0.7)';  // Rojo
      alerta = `${label} - Roja: Río desbordado hacia zonas vulnerables`;
    }

    return { color, alerta };
  };

  

  // Función para cambiar la variable seleccionada del Button Group
  const handleButtonClick = (variable) => {
    setSelectedVariable(variable);
  };

  // Función para crear un gradiente dinámico en el área del gráfico de caudal
  const getDynamicGradient = (ctx, chartArea, scales, caudalValues, umbrales) => {
    const gradientBg = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    
    // Cantidad de valores (9 en este caso: 6 del pasado, 3 del presente)
    const numValues = caudalValues.length;
    
    // Calcular la proporción de cada segmento en el gradiente
    const stepSize = 1 / (numValues - 1); // Dividir en partes iguales

    // Asignar los colores correspondientes para cada valor de caudal
    caudalValues.forEach((value, index) => {
      const color = calcularColorYAlerta(value, umbrales, `${index}h`).color;
      gradientBg.addColorStop(index * stepSize, color); // Posición del stop
    });

    return gradientBg;
  };



  
  // Función dinámica para devolver el contenedor del gráfico de la app
  const renderDynamicChartContainer = (variable) => {
    if (!selectedRio) return <Typography>Selecciona un río</Typography>; // Mostrar si no se ha seleccionado un río

    const chartType = chartTypes[variable]; // Obtiene el tipo de gráfico
    const chartData = dato[variable];  // Obtiene los datos correspondientes
    const umbrales = getUmbralesCaudal(selectedRio);  // Obtiene los umbrales específicos para el río seleccionado

    // Elige el tipo de gráfico dinámicamente
    let chart;

    if (chartType === 'bar') {
      chart = <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false,
        devicePixelRatio: 2,  // Mejor resolución
        scales: {
          y: {
            ticks: { callback: (value) => value.toFixed(1) },
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: 'black',  // Color de las etiquetas
            align: 'end',    // Alineación de la etiqueta (al final de la barra)
            anchor: 'end',   // Posición de la etiqueta
            formatter: (value) => value.toFixed(1),  // Formatear los valores
          },
        },
      }} />;

    } else if (chartType === 'line') {
      chart = (
        <Line 
          data={chartData}  // Usar chartData en lugar de dato.Caudal
          options={{
            responsive: true, 
            maintainAspectRatio: false,
            devicePixelRatio: 2,
            scales: {
              y: {
                ticks: { callback: (value) => value.toFixed(1) },
              },
            },
            plugins: {
              datalabels: {
                display: true,
                color: 'black',
                align: 'end',
                anchor: 'end',
                formatter: (value) => value.toFixed(1),
              },
            },
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return null;
              }
              return getDynamicGradient(ctx, chartArea, chart.scales, caudalValues, getUmbralesCaudal(selectedRio));
            },
          }} 
        />
      );

    } else if (chartType === 'gauge') {
      const currentCaudal = caudalValues[0]; // Valor de caudal actual
      const Caudal_1h = caudalValues[1]; // Valor de caudal a 1h
      const Caudal_2h = caudalValues[2]; // Valor de caudal a 2h
      const gaugePercent = parseFloat(Math.min(caudalValues[0] / umbrales.rojo, 1).toFixed(2)); // Aseguramos que el valor esté entre 0 y 1

      // Definir el estado del alerta según el valor del caudal actual (0h) con los umbrales personalizados
      const { color: color0h, alerta: alerta_0h } = calcularColorYAlerta(currentCaudal, umbrales, xAxisLabels[0]);
      const { color: color1h, alerta: alerta_1h } = calcularColorYAlerta(Caudal_1h, umbrales, xAxisLabels[1]);
      const { color: color2h, alerta: alerta_2h } = calcularColorYAlerta(Caudal_2h, umbrales, xAxisLabels[2]);

      // Grid layout que contiene el gauge y la descripción
      chart = (
        <Grid container alignItems="center" spacing={1}>
          {/* Gauge */}
          <Grid item xs={12} md={6}>
            {renderGaugeChart()}  {/* Renderizamos el GaugeChart dinámico */}
          </Grid>

          {/* Descripción al lado del gauge */}
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <span 
                style={{
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  backgroundColor: color0h,  // Color basado en el nivel de alerta
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></span>
              {alerta_0h}  {/* Texto de alerta */}
            </Typography>

            <Typography variant="body1">
              <span 
                style={{
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  backgroundColor: color1h,  // Color basado en el nivel de alerta
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></span>
              {alerta_1h}  {/* Texto de alerta */}
            </Typography>

            <Typography variant="body1">
              <span 
                style={{
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  backgroundColor: color2h,  // Color basado en el nivel de alerta
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></span>
              {alerta_2h}  {/* Texto de alerta */}
            </Typography>
          </Grid>

        </Grid>
      );
    }

    // Devolver el contenedor del gráfico
    return (
      <Grid item xs={8} md={10} >
        <Paper elevation={0} style={{ padding: '5px', height: '200px' }}>
          <Typography variant="h6">{variable}</Typography>
          {chart} {/* Renderiza el gráfico dinámico */}
        </Paper>
      </Grid>
    );
  };

  
  // Función para obtener los 24 documentos más recientes de Firestore y generar labels a partir de los timestamps
  // Función para obtener los datos de precipitación dinámicamente
  const fetchPrecipitationData = async (selectedRio) => {
    try {
      // Obtener las estaciones y pesos según el río seleccionado
      const { estaciones, pesos } = estacionesRios[selectedRio] || { estaciones: [], pesos: [] };
      
      const allData = {};
      const labels = [];  // Array para almacenar los timestamps formateados

      // Inicializar arrays para almacenar datos
      estaciones.forEach(estacion => {
        allData[estacion] = [];
      });

      // Obtener los datos de cada estación
      for (let i = 0; i < estaciones.length; i++) {
        const lecturasRef = collection(db, `estaciones_lluvia/${estaciones[i]}/lecturas`);
        const q = query(lecturasRef, orderBy('Fecha', 'desc'), limit(24));  // Obtener últimos 24 documentos
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          allData[estaciones[i]].push({
            id: doc.id,
            ...doc.data(),
          });

          // Solo generar los labels una vez, en la primera iteración
          if (i === 0) {
            labels.push(formatDocumentIdToDate(doc.id));  // Usa la función formatDocumentIdToDate para formatear el ID como fecha
          }
        });
      }

      // Calcular el promedio ponderado
      const weightedAverage = [];
      for (let i = 0; i < 24; i++) {
        let totalPrecipitation = 0;
        let timestamp = null;

        estaciones.forEach((estacion, index) => {
          const lectura = allData[estacion][i];
          if (lectura && lectura.Lluvia !== undefined) {
            totalPrecipitation += (lectura.Lluvia * pesos[index]);

            // Asignamos el ID como timestamp formateado si no se ha asignado aún
            if (!timestamp) {
              timestamp = formatDocumentIdToDate(lectura.id);  // Formatear el ID del documento
            }
          }
        });

        // Validar que haya un *timestamp* y un total de precipitación válido
        if (timestamp && totalPrecipitation !== null) {
          weightedAverage.push({ timestamp, precipPromedio: totalPrecipitation });
        } else {
          weightedAverage.push({ timestamp: 'Sin datos', precipPromedio: 0 });
        }
      }

      // Guardar los datos procesados y labels en el estado de lecturas
      setLecturas(weightedAverage.reverse());  // Revertimos para mostrar en orden ascendente
      setLoading(false);
    } catch (error) {
      //console.error("Error al obtener los documentos más recientes:", error);
      setErrorFirestore("Error al obtener datos de Firestore. Intente nuevamente.");
      setLoading(false);
    }
  };

  // Llamar a la función cada vez que cambie el río seleccionado
  useEffect(() => {
    if (selectedRio) {
      fetchPrecipitationData(selectedRio);  // Obtener datos basados en el río seleccionado
    }
  }, [selectedRio]);

  

  // Función para formatear el ID del documento como fecha legible
  const formatDocumentIdToDate = (id) => {
    if (!id) {
      return 'Sin datos';  // Fallback si no hay ID disponible
    }

    // Extraer año, mes, día, hora y minutos del ID
    const year = id.substring(0, 4);
    const month = id.substring(4, 6);
    const day = id.substring(6, 8);
    const hour = id.substring(8, 10);
    const minute = id.substring(10, 12);

    // Crear una fecha legible en formato DD/MM/YYYY HH:mm
    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  // Función para obtener los X datos de predicción del modelo_0h, omitiendo el documento más reciente 
  const fetchPredictionData = async (selectedRio) => {
    try {
      const lecturasRef = collection(db, `resultados_tecnorios/${selectedRio}/modelo_0h`);
      const q = query(lecturasRef, orderBy('Fecha', 'desc'), limit(7));  // Obtener 7 para luego hacer skip del más reciente
      const querySnapshot = await getDocs(q);

      const predictions = [];
      const labels = [];
      let skipFirst = true;  // Bandera para saltar el primer documento

      querySnapshot.forEach((doc) => {
        if (skipFirst) {
          skipFirst = false;
          return;  // Saltamos el primer dato
        }

        const data = doc.data();
        const prediction = data.Prediccion || 0;
        const timestamp = formatDocumentIdToDate(doc.id);

        predictions.push(prediction);
        labels.push(timestamp);
      });

      return { predictions: predictions.reverse(), labels: labels.reverse() }; // Aseguramos que los datos estén en orden ascendente
    } catch (error) {
      console.error("Error al obtener datos de Firestore:", error);
      setErrorFirestore("Error al obtener datos de Firestore. Intente nuevamente.");
      setLoading(false);
    }
  };

  // Llamar a la función y combinar los datos de predicción con los de caudal actuales
  useEffect(() => {
    if (selectedRio) {
      const fetchAndCombineData = async () => {
        setLoading(true);
  
        // Obtener los 6 datos del pasado
        const { predictions, labels } = await fetchPredictionData(selectedRio);
  
        // Datos de caudal actuales (mapa_0h, mapa_1h, mapa_2h)
        const currentCaudalValues = [
          caudalData.mapa_0h?.pred_caudal || 0,
          caudalData.mapa_1h?.pred_caudal || 0,
          caudalData.mapa_2h?.pred_caudal || 0,
        ];
  
        const currentLabels = [
          formatTimestamp(caudalData.mapa_0h?.timestamp),  // Sin cambios para 0h
          formatTimestamp(caudalData.mapa_1h?.timestamp, 1),  // Sumar 1 hora para 1h
          formatTimestamp(caudalData.mapa_2h?.timestamp, 2),  // Sumar 2 horas para 2h
        ];
  
        // Combinar las predicciones pasadas con los datos de caudal actuales
        const combinedCaudalValues = [...predictions, ...currentCaudalValues];
        const combinedLabels = [...labels, ...currentLabels]; // Aquí estamos definiendo correctamente los labels históricos
  
        // Actualizar el estado de los datos para el gráfico
        setChartData({
          labels: combinedLabels,  // Aquí están los labels correctos
          datasets: [
            {
              label: 'Caudal (m³/s)',
              data: combinedCaudalValues,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea, scales } = chart;
                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx, chartArea, scales, combinedCaudalValues, getUmbralesCaudal(selectedRio));
              },
              borderColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea, scales } = chart;
                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx, chartArea, scales, combinedCaudalValues, getUmbralesCaudal(selectedRio));
              },
              tension: 0.3,
              fill: true,
            },
          ],
        });
  
        setLoading(false);
      };
  
      fetchAndCombineData();  // Ejecutar la función para obtener y combinar los datos
    }
  }, [selectedRio, caudalData]);  // Ejecutar cada vez que cambie el río seleccionado o los datos de caudal
  
  
  // Obtener los datos de Firestore para los mapas del río seleccionado
  // Obtener los datos de Firestore para los mapas del río seleccionado
  useEffect(() => {
    if (!selectedRio) return;  // Si no se ha seleccionado un río, no hacer nada
  
    setLoading(true); // Iniciar el estado de carga cada vez que cambie el río
  
    const unsubscribe = onSnapshot(
      collection(db, `resultados_tecnorios/${selectedRio}/mapas_inundacion`),  // Ruta dinámica según el río seleccionado
      (snapshot) => {
        let newCaudalData = {};
        snapshot.forEach((doc) => {
          newCaudalData[doc.id] = doc.data();
        });
        setCaudalData(newCaudalData);
  
        // Verificar si existe el archivo GeoJSON en Firestore
        const geojsonFileName = newCaudalData.mapa_0h?.geojson_file;
        if (geojsonFileName) {
          // Actualizar la URL pública del archivo GeoJSON del río seleccionado
          const publicUrl = `https://storage.googleapis.com/bucket_mapas_p_bv/${geojsonFileName}`;
          setGeoJsonUrl(publicUrl); // Actualizar el estado con la URL pública del archivo
          //console.log('URL del GeoJSON:', geoJsonUrl);
        } else {
          setGeoJsonUrl(null); // Si no existe archivo, limpiar el estado
        }
  
        setLoading(false); // Termina el estado de carga cuando los datos están listos
      },
      (error) => {
        console.error("Error al obtener los datos de Firestore:", error);
        setErrorFirestore("Error al obtener datos de Firestore. Intente nuevamente.");
        setLoading(false);
      }
    );
  
    return () => unsubscribe();
  }, [selectedRio]);  // Se vuelve a ejecutar cada vez que el río seleccionado cambia
  

  

  // Función para añadir la leyenda al mapa
  const addLegendToMap = (map) => {
    const legendDiv = document.createElement('div');
    legendDiv.innerHTML = `
      <h4 style="margin-bottom: 5px;">Profundidad</h4>
      <ul style="list-style:none; padding:0; margin:0;">
        <li style="display: flex; align-items: center; margin-bottom: 5px;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color:#FF0000; border: 1px solid #000; margin-right: 10px;"></span> 
          >300 cm
        </li>
        <li style="display: flex; align-items: center; margin-bottom: 5px;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color:#FF5722; border: 1px solid #000; margin-right: 10px;"></span> 
          200-300 cm
        </li>
        <li style="display: flex; align-items: center; margin-bottom: 5px;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color:#FFC107; border: 1px solid #000; margin-right: 10px;"></span> 
          100-200 cm
        </li>
        <li style="display: flex; align-items: center; margin-bottom: 5px;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color:#28A745; border: 1px solid #000; margin-right: 10px;"></span> 
          50-100 cm
        </li>
        <li style="display: flex; align-items: center;">
          <span style="display: inline-block; width: 12px; height: 12px; background-color:#007BFF; border: 1px solid #000; margin-right: 10px;"></span> 
          <50 cm
        </li>
      </ul>
    `;
    
    // Estilo ajustado para que ocupe menos espacio
    legendDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
    legendDiv.style.padding = '5px';
    legendDiv.style.margin = '10px';
    legendDiv.style.borderRadius = '5px';
    legendDiv.style.border = '1px solid black';
    legendDiv.style.fontSize = '12px';  // Tamaño de fuente reducido
    legendDiv.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.3)';
  
    // Añadir la leyenda en la posición inferior izquierda del mapa
    map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legendDiv);
  };

  // Render del mapa con escala de colores de profundidades
  const renderGoogleMap = () => (
    <GoogleMap
      center={mapCenter}  // El centro del mapa cambia dinámicamente
      zoom={14}
      mapContainerStyle={mapContainerStyle}
      mapId="4dad983352d7a460"
      onLoad={(map) => {
        if (geoJsonUrl) {
          map.data.forEach(function (feature) {
            map.data.remove(feature);
          });
          map.data.loadGeoJson(geoJsonUrl, {}, (features) => {
            map.data.setStyle((feature) => {
              const D = feature.getProperty('D');
              let fillColor;
              if (D >= 400) fillColor = '#FF0000';
              else if (D >= 300) fillColor = '#FF5722';
              else if (D >= 200) fillColor = '#FFC107';
              else if (D >= 100) fillColor = '#28A745';
              else fillColor = '#007BFF';
              return {
                fillColor: fillColor,
                strokeColor: fillColor,
                strokeWeight: 0.1,
                fillOpacity: 0.9,
              };
            });
          });
        }
        addLegendToMap(map);
      }}
    />
  );

  // Función para iniciar sesión con email y contraseña
  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError('');
    } catch (error) {
      setError('Error al iniciar sesión. Verifica tus credenciales.');
    }
  };

  // Función para iniciar sesión anónimo
  const handleAnonymousLogin = async () => {
    try {
      await signInAnonymously(auth);
      setError('');
    } catch (error) {
      setError('Error al iniciar sesión de forma anónima.');
    }
  };

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // Verificar si los datos de caudales están cargados
  if (loading) {
    return <div>Cargando datos...</div>;  // Mostrar estado de carga mientras los datos se obtienen
  }

  if (errorFirestore) {
    return (
      <div>
        <Typography variant="h6" color="error">{errorFirestore}</Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>Reintentar</Button>
      </div>
    );
  }

  // Extraer x-axis labels para los gráficos
  //const xAxisLabels = data ? Object.keys(data.paramo_buena_vista).map(key => `${key} h`) : [];


  //Primer grafico con precipitaciones en 24h

  // Preparar datos para el gráfico de caudales de Firestore con colores dinámicos
  //const caudalLabels = ['0h', '1h', '2h'];
  const caudalValues = [
    caudalData.mapa_0h?.pred_caudal || 0,
    caudalData.mapa_1h?.pred_caudal || 0,
    caudalData.mapa_2h?.pred_caudal || 0,
  ];

  // Obtener el valor máximo de los caudales
  const maxCaudalValue = Math.max(...caudalValues);
  const caudalMaxThreshold = maxCaudalValue + 0.25 * maxCaudalValue;  // Incremento del 25%

  // Se definen colores de caudales 
  const caudalColors = caudalValues.map(value => {
    if (value < 15) return 'rgba(0,123,255,0.7)';  // Azul para caudales bajos
    if (value < 50) return 'rgba(40,167,69,0.7)';  // Verde para caudales medios
    if (value < 100) return 'rgba(255,193,7,0.7)';  // Amarillo para caudales altos
    if (value < 200) return 'rgba(255,87,34,0.7)';  // Naranja para caudales muy altos
    return 'rgba(255,0,0,0.7)';  // Rojo para caudales extraordinarios
  });


  // Mapeo de colores basado en los umbrales
  const colores_umbral = [
    'rgba(0,123,255,0.7)',  // Azul para caudales bajos (<15)
    'rgba(40,167,69,0.7)',  // Verde para caudales medios (15-30)
    'rgba(255,193,7,0.7)',  // Amarillo para caudales altos (30-80)
    'rgba(255,87,34,0.7)',  // Naranja para caudales muy altos (80-150)
    'rgba(255,0,0,0.7)'     // Rojo para caudales extraordinarios (>150)
  ];

  // Función para calcular los colores dinámicamente basados en el caudal y umbrales
  const getCaudalColor = (value, umbrales) => {
    if (value < umbrales.azul) return 'rgba(0,123,255,0.7)';  // Azul para caudales bajos
    if (value < umbrales.verde) return 'rgba(40,167,69,0.7)';  // Verde para caudales medios
    if (value < umbrales.amarillo) return 'rgba(255,193,7,0.7)';  // Amarillo para caudales altos
    if (value < umbrales.naranja) return 'rgba(255,87,34,0.7)';  // Naranja para caudales muy altos
    return 'rgba(255,0,0,0.7)';  // Rojo para caudales extraordinarios
  };

  const getGradient = (ctx, chartArea, scales) => {
    const gradientBg = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
  
    // Usar los valores de caudal para calcular los colores de gradiente
    gradientBg.addColorStop(0, getCaudalColor(caudalValues[0], getUmbralesCaudal(selectedRio)));  // Primer punto
    gradientBg.addColorStop(0.5, getCaudalColor(caudalValues[1], getUmbralesCaudal(selectedRio)));  // Punto intermedio
    gradientBg.addColorStop(1, getCaudalColor(caudalValues[2], getUmbralesCaudal(selectedRio)));  // Último punto
  
    return gradientBg;
  };

  //gráficos dinámicos con botones
  //gráficos dinámicos con botones
  const dato = {
    Precipitacion: {
      labels: lecturas.map((doc) => doc.timestamp),  // Usar el timestamp con la corrección de UTC-6
      datasets: [
        {
          label: 'Precipitación promedio en la cuenca 24h (mm)',
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          data: lecturas.map((doc) => doc.precipPromedio),  // Promedio ponderado de las precipitaciones
        },
      ],
    },

    Caudal: {
      labels: chartData?.labels || [],  // Etiquetas combinadas de los 6 históricos + 3 actuales
      datasets: [
        {
          label: 'Caudal (m³/s)',
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea, scales } = chart;

            if (!chartArea) {
              return null;  // Salir si chartArea aún no está definido
            }

            // Aplicar gradiente en base a los valores combinados
            return getDynamicGradient(ctx, chartArea, scales, chartData?.datasets[0]?.data || [], getUmbralesCaudal(selectedRio));
          },
          borderColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea, scales } = chart;

            if (!chartArea) {
              return null;  // Salir si chartArea aún no está definido
            }

            // Aplicar el mismo gradiente para el borde (línea)
            return getDynamicGradient(ctx, chartArea, scales, chartData?.datasets[0]?.data || [], getUmbralesCaudal(selectedRio));
          },
          data: chartData?.datasets[0]?.data || [],  // Usar los datos combinados de caudal (6 históricos + 3 actuales)
          tension: 0.3,
          fill: true,  // Relleno bajo la línea
        },
      ],
    },
    
    Alerta: {
      datasets: [
        {
          label: 'Alerta',
          backgroundColor: 'rgba(255,159,64,0.6)',
          borderColor: 'rgba(255,159,64,1)',
          data: [caudalValues[0]], // Caudal actual
        },
      ],
    },
  };

  
  

  // Mapa del tipo de gráfico por cada variable
  const chartTypes = {
    Precipitacion: 'bar',  // Gráfico de barras para Precipitación
    Caudal: 'line',  // Gráfico de barras para Caudal
    Alerta: 'gauge',  // Gráfico gauge para Profundidad
  };

  // Interfaz para seleccionar el río
  // Interfaz para seleccionar el río
  const renderRioSelector = () => (
    <Grid item xs={7} md={6} style={{ textAlign: 'left' }}>
      
      <select 
        value={selectedRio} 
        onChange={handleRioSelection} 
        style={{ padding: '10px', borderRadius: '5px', marginBottom: '5px', width: '150px' }}
      >
        <option value="rio_p_bv">Río Buena Vista</option>
        <option value="rio_ipis">Río Ipis</option>
        <option value="rio_ma_1">Río María Aguilar</option>
        {/* Agrega más ríos según sea necesario */}
      </select>
    </Grid>
  );
  

  
//Construccion de pagina
return (
  
  <Grid container style={{ padding: '5px' }} align="center" xs={12} md={12}  rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    
    {/* Título del dashboard */}
    <Grid container justifyContent="center" style={{ padding: '10px'  }}>
      <Typography variant="h5" >
        Tecnoríos Dashboard
      </Typography>
    </Grid>

    
    
    {/* Botón para cerrar sesión */}
    <Grid container alignItems="center" justifyContent="space-between"  spacing={1} style={{ marginBottom: '1px',marginRight: '20px' }}>
      <Grid item >
        {/* Render del selector del río */}
        {renderRioSelector()}
      </Grid>
      <Grid item xs={5} md={6} style={{ textAlign: 'right' }}>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleLogout} 
          style={{ fontSize: '11px', padding: '5px 5px', marginLeft: '10px' }}
        >
          Cerrar Sesión
        </Button>
      </Grid>
    </Grid>

    {/* Sección de mapa tecnorios */}
    <Grid item xs={12} md={12} justifyContent="center" style={{ marginBottom: '1px' }}>
        <Paper elevation={0} style={{ padding: '10px', height: '300px', marginBottom: '5px' }}>
          <Typography variant="h6" gutterBottom>Mapa de inundación actual</Typography>
          {renderGoogleMap()}
        </Paper>
      </Grid>

    {/* Panel inferior */}
    <Grid item container spacing={1}>

      {/* Button Group para seleccionar Precipitación, Caudal o Alerta */}
      <Grid item xs={4} md={2} >
        <Paper elevation={0} style={{ padding: '5px', height: '240px' }}>
          <Typography variant="h6">Selección</Typography>
          <ButtonGroup size="medium" orientation="vertical" variant="contained" color="primary" aria-label="Selección">
            <Button onClick={() => handleButtonClick('Alerta')}> Alerta </Button>
            <Button onClick={() => handleButtonClick('Caudal')}> Caudal </Button>
            <Button onClick={() => handleButtonClick('Precipitacion')}> Lluvia </Button>
          </ButtonGroup>
        </Paper>
      </Grid>

      {/* Renderiza el contenedor del gráfico dinámico */}
      {renderDynamicChartContainer(selectedVariable)}

    </Grid>

  </Grid>
);

}

function MainApp() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyA3SNULXjpfYU_kLCxj6NHTmTxijH3LOFc">
      <App />
    </LoadScript>
  );
}

export default MainApp;

